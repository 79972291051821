/*** App styles ***/
html { width: 100%; height: 100%; }
body { width: 100%; height: 100%; color: black; font-size: 18px; line-height: 1.2; font-family: 'Plus Jakarta Sans', sans-serif; background: #f5f5f5; }
#root { display: flex; width: 100%; height: 100%; }

/*** Animations ***/
@keyframes spin {
  0% {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(1turn)
  }
}

.spin {
  animation: spin 2s infinite linear;
}
